<template>
  <div v-if="!fetching" class="candidate-profile col-sm-9" style="margin: auto">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <h3 class="pb-2 text-primary mt-1">Edit Job</h3>
      <div class="w-100 d-flex justify-content-start sticky-header pt-3">
        <Stepper
          :options="stepperOptions"
          :activeBlock="block"
          :goTo="slideTo"
        ></Stepper>
        <h6
          v-if="job_id"
          class="pb-2 text-primary mt-1 font-weight-bold"
          style="white-space: nowrap"
        >
          JOB ID: {{ job_id }}
        </h6>
      </div>
    </div>
    <span v-if="block === 0">
      <JobInfo @step="gotoStep"></JobInfo>
    </span>
    <span v-if="block === 1">
      <JobAdditionalInfo @step="gotoStep"></JobAdditionalInfo>
    </span>
    <!-- <span v-if="block === 2">
      <JobBenefits @step="gotoStep"></JobBenefits>
    </span> -->
    <span v-if="block === 2">
      <JobTemplate @step="gotoStep"></JobTemplate>
    </span>
    <span v-if="block === 3">
      <JobPreview @step="gotoStep"></JobPreview>
    </span>
  </div>
</template>

<script>
import AddJob from "./AddJob.vue";
export default {
  extends: AddJob,
};
</script>
